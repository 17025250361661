<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col>
          <div id="component-breadcrumbs">
            <b-breadcrumb
              class="breadcrumb-slash"
              :items="breadcrumbItems"
            />
          </div>
        </b-col>
        <b-col v-if="readOnly && UserType === 'User' && getCurrentScopes.indexOf('st2.editclientsandusers') !== -1">
          <div class="float-right mr-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="editScreen()"
            >
              Edit
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-form class="p-2">
        <b-row>
          <b-col
            md="12"
            class="pr-6"
          >
            <b-row class="pb-2 pt-2">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Client Name"
                  rules="required"
                >
                  <b-form-group
                    label="Client Name"
                    label-for="clientname"
                    class="required-data"
                  >
                    <b-form-input
                      id="clientname"
                      v-model="clientObject.Name"
                      placeholder=""
                      name="clientname"
                      :state="errors.length > 0 ? false:null"
                      :readonly="readOnly"
                      @keypress.enter.prevent
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="clientObject.ClientID">
                <b-form-group
                  label="Client ID"
                  label-for="clientid"
                >
                  <b-form-input
                    id="clientid"
                    v-model="clientObject.ClientID"
                    placeholder=""
                    name="clientid"
                    :readonly="true"
                    @keypress.enter.prevent
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="pb-2 pt-2">
              <b-col md="4">
                <b-form-group
                  label="Account Manager"
                  label-for="accountManager"
                  class="required-data"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Account Manager"
                    rules="required"
                  >
                    <v-select
                      id="accountManager"
                      v-model="AMUserID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="accountManager"
                      placeholder=""
                      name="accountManager"
                      :disabled="readOnly"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="CSR"
                  label-for="csr"
                >
                  <v-select
                    id="csr"
                    v-model="CSRUserID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="CSRoption"
                    placeholder=""
                    name="csr"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Deal Owner"
                  label-for="dealOwner"
                >
                  <v-select
                    id="dealOwner"
                    v-model="DealOwnerUserID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="dealOwner"
                    placeholder=""
                    name="dealOwner"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="pb-2 pt-2">
              <b-col md="4">
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="clientObject.CRMStatus"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="option"
                    placeholder=""
                    name="status"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Client Instructions"
                  label-for="clientInstructions"
                >
                  <b-row>
                    <b-col>
                      <b-form-checkbox
                        id="requireAuthentication"
                        v-model="RequireVideoAuthentication"
                        name="requireAuthentication"
                        :disabled="readOnly"
                      >
                        Require authentication to view videos
                      </b-form-checkbox>

                      <b-form-checkbox
                        id="blockMailing"
                        v-model="MailInvoices"
                        name="blockMailing"
                        :disabled="readOnly || getCurrentScopes.indexOf('st2.viewlist') === -1"
                      >
                        Block invoice Mailing
                      </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        id="vendingService"
                        v-model="IsVendingServiceNotAllowed"
                        name="vendingService"
                        :disabled="readOnly"
                      >
                        Vending of services not allowed
                      </b-form-checkbox>
                      <div class="contract-block">
                        <b-form inline>
                          <b-form-checkbox
                            id="contract"
                            v-model="IsContractInPlace"
                            name="vendingService"
                            :disabled="readOnly"
                          >
                            Contract in place
                          </b-form-checkbox>
                          <b-form-group
                            label="Expires"
                            label-for="expires"
                            class="pl-5"
                          >
                            <b-form-input
                              :key="readOnly || !IsContractInPlace"
                              id="expires"
                              v-model="Expires"
                              class="ml-1"
                              type="date"
                              name="expires"
                              :readonly="readOnly || !IsContractInPlace"
                            />
                          </b-form-group>
                        </b-form>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

            </b-row>
            <div class="accordion">
              <div
                class="accordion-title"
                @click="toggleAccordion('INSTRUCTIONS')"
              >
                <div
                  class="collapse-arrow"
                  :class="{open: tab.INSTRUCTIONS}"
                />
                <h3>Special Instructions</h3>
              </div>
              <b-row v-if="tab.INSTRUCTIONS">
                <b-col
                  md="12"
                  class="pr-6"
                >
                  <b-row class="mt-3">
                    <b-col>
                      <b-form-group
                        label="Special Instructions Investigator"
                        label-for="specIn"
                      >

                        <div
                          class="collapse-textarea"
                          :class="{expanded: !isExpandedInvestigatorInstructions}"
                        >
                          <textarea
                            id="specIn"
                            v-model="clientObject.SpecialInstructionsInvestigator"
                            class="expandable-textarea"
                            :class="[isExpandedInvestigatorInstructions ? 'not-expanded' : 'expanded']"
                            contentEditable
                            :readOnly="readOnly"
                          />
                          <div @click="isExpandedInvestigatorInstructions = !isExpandedInvestigatorInstructions">
                            <span
                              class="expand-arrow mt-n1"
                              :class="[isExpandedInvestigatorInstructions ? 'not-expanded' : 'expanded']"
                            >&#187;</span>
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <b-form-group
                        label="Special Instructions Manager"
                        label-for="specMa"
                      >

                        <div
                          class="collapse-textarea"
                          :class="{expanded: !isExpandedManagerInstructions}"
                        >
                          <textarea
                            id="specMa"
                            v-model="clientObject.SpecialInstructionsManager"
                            class="expandable-textarea"
                            :class="[isExpandedManagerInstructions ? 'not-expanded' : 'expanded']"
                            contentEditable
                            :readOnly="readOnly"
                          />
                          <div @click="isExpandedManagerInstructions = !isExpandedManagerInstructions">
                            <span
                              class="expand-arrow mt-n1"
                              :class="[isExpandedManagerInstructions ? 'not-expanded' : 'expanded']"
                            >&#187;</span>
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <hr>

            <b-row>
              <b-col md="8">
                <h3 class="font-weight-bolder mb-1">
                  Branches
                </h3>
                <div
                  class="row justify-content-between"
                  style="margin-bottom: 8px;"
                >
                  <div class="col-6">
                    <div>
                      <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
                        <div>
                          <b-button
                            :disabled="disabledBranch() || readOnly"
                            block
                            variant="primary"
                            style="
                  justify-content: space-around;
                  display: flex;
                  border-radius: 0;
                "
                            @click="$router.push('/clients/' + clientObject.ClientID + '/branch/add')"
                          >
                            <span>+ Add Branch</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2" />
                  <div class="col-3">
                    <b-form-group>
                      <div class="d-flex align-items-right">
                        <label class="mr-1" />
                        <b-form-input
                          v-model="filterBranches"
                          placeholder="Filter Branches"
                          type="text"
                          class="d-inline-block"
                          @keypress.enter.prevent
                        />
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <b-row>
                  <b-col md="12">
                    <vue-good-table
                      :columns="columns"
                      :rows="filterRows"
                      :rtl="direction"
                      :search-options="{ enabled: false }"
                      :pagination-options="{ enabled: false }"
                      class="mb-2"
                    >
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                        <!-- Column: Name -->
                        <span
                          v-if="props.column.field === 'fullName'"
                          class="text-nowrap"
                        >
                          <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                          />
                          <span class="text-nowrap">{{ props.row.fullName }}</span>
                        </span>

                        <!-- Column: Status -->
                        <span v-else-if="props.column.field === 'status'">
                          <b-badge :variant="statusVariant(props.row.status)">
                            {{ props.row.status }}
                          </b-badge>
                        </span>
                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                          <span>
                            <b-dropdown
                              v-if="getCurrentScopes.indexOf('st2.editclientsandusers') !== -1"
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template v-slot:button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="text-body align-middle mr-25"
                                />
                              </template>
                              <b-dropdown-item>
                                <feather-icon
                                  icon="Edit2Icon"
                                  class="mr-50"
                                />
                                <span>
                                  <router-link :to="'/clients/' + clientObject.ClientID + '/branch/' + props.row.ID + '/edit'">Edit Branch</router-link>
                                </span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>

                    </vue-good-table>
                  </b-col>
                  <b-col>

                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                <h5 class="font-weight-bolder">
                  Sub Types
                </h5>
                <div class="white-bg-container border-gray">
                  <SubTypes
                    v-for="(subType, index) of ClientClaimSubTypes"
                    :key="index"
                    :index="index"
                    :read-only="readOnly"
                    @updateSubType="updateSubType"
                    @deleteSubType="deleteSubType"
                  />
                  <b-button
                    type="button"
                    variant="primary"
                    class="w-100"
                    :disabled="readOnly || addBtnDisabled"
                    @click="addSubType()"
                  >
                    <span>Add Subtype</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-form-group
              label="Client Billing Notes"
              label-for="clientBillingNotes"
              class="large-text"
            >
              <b-form-textarea
                id="clientBillingNotes"
                v-model="clientObject.BillingNotes"
                rows="3"
                no-resize
                :readonly="readOnly || getCurrentScopes.indexOf('st2.editclientbillingnotes') === -1"
              />
            </b-form-group>

            <b-row
              v-if="!readOnly"
              class="pb-2 pt-2"
            >
              <b-col>
                <div class="d-flex justify-content-between">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="resetForm()"
                    >
                      Cancel
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      :disabled="isLoading"
                      @click.prevent="validationForm()"
                    >
                      Save
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete-branch"
      ok-only
      ok-variant="danger"
      ok-title="Delete Branch"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Branch"
      @ok="confirmDeleteBranch()"
    >
      <div v-if="branchToDelete !== null">
        Are you sure you want to delete branch {{ branchToDelete.Name }} ? This cannot be
        undone.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {
    BBadge,
    BBreadcrumb,
    BButton,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import "vue-good-table/dist/vue-good-table.css";
import vSelect from 'vue-select'
import {required} from '@validations'
import store from "@/store";
import {VueGoodTable} from "vue-good-table";
import {mapGetters, mapMutations} from "vuex";
import APIService from "@core/utils/APIService";
import SubTypes from "@/views/client/components/SubTypes";

const apiService = new APIService();

export default {
  components: {
    SubTypes,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BBreadcrumb,
    BFormCheckbox,
    BBadge,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      branchToDelete: null,
      clientObject: {
        CRMStatus: {title: 'Active', value: 'Active'}
      },
      option: [{ title: 'Active', value: 'Active' }, { title: 'Prospect', value: 'Prospect' }, { title: 'Inactive', value: 'Inactive' }],
      accountManager: [],
      AMUserID: null,
      CSRUserID: null,
      DealOwnerUserID: null,
      CSRoption: [],
      dealOwner: [],
      RequireVideoAuthentication: false,
      MailInvoices: false,
      IsVendingServiceNotAllowed: false,
      filterBranches: '',
      required,

      ClientClaimSubTypes: [],

      BillingNotes: '',
      readOnly: false,
      addBtnDisabled: false,
      isLoading: false,
      rows: [],
      filterRows: [],
      UserType: "",
      Expires: null,
      IsContractInPlace: false,

      tab: {
        INSTRUCTIONS: false,
      },
      isExpandedManagerInstructions: false,
      isExpandedInvestigatorInstructions: false,

      columns: [
        {
          label: "Branch",
          field: "Name",
        },
        {
          label: "Status",
          field: "CRMStatus",
        },
        {
          label: "Requestors",
          field: "Requestors",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
      getSubTypes: "clients/getSubTypes",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Clients',
          href: '/clients'
        },
        {
          text: this.clientObject.ClientID ? this.clientObject.Name : 'Create New'
        }
      ]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  watch: {
    filterBranches(val) {
      this.filterRows = this.rows.filter(i =>
          (i.Name.toUpperCase().indexOf(val.toUpperCase()) !== -1) ||
          (i.CRMStatus.toUpperCase().indexOf(val.toUpperCase()) !== -1)
      )
    },
    ClientClaimSubTypes(val) {
        const items = [];
        val.map(item => {
            if(item.value) items.push(item.value)
        })
        const out  = items.join(',')
        this.setSubTypes(out);
        this.addBtnDisabled = val.length !== items.length
    },
  },
  async created() {
    try {
      this.UserType = localStorage.getItem("userType");
      const response = await apiService.get('users/user').then(res => res.data).catch(error => error)
      this.dealOwner = this.accountManager = this.CSRoption = response.Users.map(d => ({title: d.LastName + ", " + d.FirstName, value: d.UserAccountID}))
      if (this.dealOwner && this.accountManager && this.CSRoption) {
        var existingClientId = this.$route.params.clientID;
        var readOnly = this.$route.meta.view;
        this.readOnly = readOnly || false;
        if (existingClientId) {
          this.getClientData(existingClientId)
        }
      }
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    ...mapMutations({
        setCurrentClients: "clients/setCurrentClients",
        setSubTypes: "clients/setSubTypes",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    setCurrentClientsFill() {
      this.clientObject = {...this.clientObject, AMUserID: this.AMUserID[0]}
      this.setCurrentClients(this.clientObject);
    },
    resetForm() {
      this.clientObject = {};
      this.$router.push('/clients');
    },
    deleteBranchWithPrompt(branchObj) {
      this.branchToDelete = branchObj;
      this.$bvModal.show("modal-delete-branch");
    },
    toggleAccordion(tab) {
      this.tab[tab] = !this.tab[tab]
    },
    confirmDeleteBranch() {
      if (!this.branchToDelete) {
        return;
      }
      apiService
          .delete("client/branch/" + this.branchToDelete.ID)
          .then((resp) => {
            if (resp) {
              this.showToast("success", "top-center", 4000, "Client deleted");
              this.rows =  this.rows.filter(r => r.ID !== this.branchToDelete.ID)
            }
          });
      this.branchToDelete = null;
    },
    processForm() {
      this.isLoading = true;
      let postData = {};
      postData.CRMStatus = (this.clientObject.CRMStatus && this.clientObject.CRMStatus.value) || null;
      postData.AMUserID = (this.AMUserID && this.AMUserID.value) || 0;
      postData.DealOwnerUserID = (this.DealOwnerUserID && this.DealOwnerUserID.value) || 0;
      postData.CSRUserID = (this.CSRUserID && this.CSRUserID.value) || 0;
      postData.RequireVideoAuthentication = this.RequireVideoAuthentication;
      postData.IsVendingServiceNotAllowed = this.IsVendingServiceNotAllowed;
      postData.MailInvoices = this.MailInvoices;
      postData.BillingNotes = this.clientObject.BillingNotes || null;
      postData.Name = this.clientObject.Name;
      postData.ClientType = 'CLIENT';
      postData.ClientID = this.clientObject.ClientID;
      postData.SpecialInstructionsInvestigator = this.clientObject.SpecialInstructionsInvestigator;
      postData.SpecialInstructionsManager = this.clientObject.SpecialInstructionsManager;
      postData.ClientClaimSubTypes = this.getSubTypes;
      postData.IsContractInPlace = this.IsContractInPlace;
      postData.Expires = this.IsContractInPlace ? this.Expires : null;

      var endpoint = 'client/create';
      if (postData.ClientID) {
        endpoint = 'client/' + postData.ClientID;
      }

      apiService.post(endpoint, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Client saved!');
          if (res.data && res.data.ClientID) {
            this.fillClientData(res.data);
            this.readOnly = true;
            this.$router.push('/clients/view/' + res.data.ClientID).catch((err) => {
              if (err.name !== "NavigationDuplicated") {
                throw error;
              }
            })
          }
        } else {
          this.isLoading = false;
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.processForm()
        }
      })
    },
    disabledBranch() {
      return !this.clientObject.ClientID && !this.readOnly
    },
    editScreen() {
      this.readOnly = false;
      this.isLoading = false;
    },

    fillClientData(data) {
      this.clientObject = data;
      this.AMUserID = this.accountManager.filter(i => i.value === data.AMUserID) || null;
      if (this.AMUserID)
        this.AMUserID.value = data.AMUserID;
      this.DealOwnerUserID = this.dealOwner.filter(i => i.value === data.DealOwnerUserID) || null;
      if (this.DealOwnerUserID)
        this.DealOwnerUserID.value = data.DealOwnerUserID;
      this.CSRUserID = this.CSRoption.filter(i => i.value === data.CSRUserID) || null;
      if (this.CSRUserID)
        this.CSRUserID.value = data.CSRUserID;
      this.filterRows = this.rows = data.Branches.sort((a,b) => (a.CRMStatus > b.CRMStatus) ? 1 : ((b.CRMStatus > a.CRMStatus) ? -1 : 0));
      this.RequireVideoAuthentication = data.RequireVideoAuthentication;
      this.IsVendingServiceNotAllowed = data.IsVendingServiceNotAllowed;
      this.MailInvoices = data.MailInvoices;
      this.Expires = null;
      if (data.Expires && data.IsContractInPlace) {
        this.Expires = data.Expires.split("T")[0]
      }
      this.IsContractInPlace = data.IsContractInPlace;
      const items = [];
      let fillSubTypes = [];
      if (data.ClientClaimSubTypes && data.ClientClaimSubTypes.indexOf(',') !== -1) {
        fillSubTypes = data.ClientClaimSubTypes.split(',');
      } else if (data.ClientClaimSubTypes) {
        fillSubTypes = [data.ClientClaimSubTypes]
      }
      fillSubTypes.map(item => {
        items.push({title: item, value: item})
      })
      this.ClientClaimSubTypes = items;
    },

    getClientData(ClientID) {
      apiService
          .get("client/" + ClientID + "/detail?isActiveBranches=false")
          .then((response) => {
            this.fillClientData(response.data)
          })
          .then(() => {
            this.setCurrentClientsFill();
          })
    },
    addSubType() {
      const item = {
        title: "",
        value: "",
      };
      this.ClientClaimSubTypes.push(item)
    },
    deleteSubType(index) {
      this.ClientClaimSubTypes.splice(index, 1);
    },
    updateSubType(val, index) {
      this.ClientClaimSubTypes.splice(index, 1, val);
    },
  },
}
</script>

<style>
hr{
  border: 1px solid #ebe9f1;
}
.large-text label {
  font-size: 1.1rem;
}
/*#dealOwner, #csr, #accountManager {
}*/
#dealOwner div div, #csr div div, #accountManager div div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 3rem);
  height: 2rem;
}
.vs__selected-options {
  padding: 0 2px;
  min-height: 2rem;
}
.accordion-title h3 {
  color: white;
  font-weight: bold;
  margin: 0.5rem;
}
.accordion-title {
  background: #476dae;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accordion {
  margin-top: 0.2rem;
}
.collapse-arrow {
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  transform: rotate(-135deg);
  transition-duration: 200ms;
}
.collapse-arrow.open {
  transform: rotate(45deg);
}
</style>


